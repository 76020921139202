<template>
  <div class="admin-users">
    <p>
      Please select the options for the report and submit.
      <br>
      A report will be generated and sent to your account email address.
    </p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item prop="season" label="Season">
            <el-select
              v-model="filters.season"
              filterable
              multiple
              placeholder="Select a season"
            >
              <el-option
                v-for="season in seasons"
                :label="season.name"
                :key="season.value"
                :value="season.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="fileType" label="File Type">
            <el-select
              v-model="filters.fileType"
              placeholder="Select a file type"
            >
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <action-buttons
      submit
      submitText="Submit"
      :cancel="false"
      @submit="sendReport"
    />
  </div>
</template>

<script>
import moment from 'moment-timezone';
import ActionButtons from '@/components/ActionButtons.vue';
import { errormsg, mysidelineSeasons } from '../../../utils/constants';

export default {
  name: 'EighteenMonthRegistrationReport',
  components: {
    ActionButtons
  },
  data() {
    return {
      filters: {
        fileType: 'csv',
        userLevel: '',
        season: '',
      },
      filterRules: {
        fileType: {
          required: true,
          message: errormsg.select_option,
          trigger: 'blur',
        },
        season: {
          required: true,
          message: errormsg.select_option,
          trigger: 'blur',
        }
      },
      fileTypes: [
        { name: 'CSV', type: 'csv', default: true }
      ],
    };
  },
  computed: {
    clearance() {
      return this.$store.getters['user/activeRole'].type;
    },
    seasons() {
      return mysidelineSeasons.map(v => ({ name: v.toString(), value: v }));
    }
  },
  methods: {
    sendReport() {
      this.$refs.filters.validate((valid) => {
        if (valid) {
          this.$store.commit('root/loading', true);
          const body = {
            reportType: 'eighteen-month-registration',
            fileType: this.filters.fileType,
            filters: {
              seasons: this.filters.season,
            },
            meta: {
              timezone: moment.tz.guess(),
            }
          };
          const url = '/nrl/api/v1/admin/reports';
          this.$http
            .post(url, body)
            .then((response) => {
              this.$store.commit('root/loading', false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit('root/loading', false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .el-input, .el-select {
    width: 100%;
  }
</style>
