<template>
  <div class="member-search">
    <el-row>
      <el-row class="search-bar" :gutter="10">
        <el-col :span="6">
          <el-input
            autocomplete="off"
            @keyup.native="debouncedInput"
            v-model="search"
            placeholder="Search..."
          ></el-input>
        </el-col>
        <el-col :span="6">
          <el-button @click="filterVisible = true">Filters</el-button>
        </el-col>
      </el-row>

      <el-dialog :visible.sync="filterVisible" @close="filterVisible = false">
        <filter-page
          @cancel="filterVisible = false"
          @submit="sendFilter"
          @clear="sendFilter"
        />
      </el-dialog>
      <data-tables-server
        id="pendingList"
        :filters="filters"
        :data="tableData"
        :table-props="tableProps"
        :pagination-props="paginationProps"
        :page-size="pageSize"
        :total="total"
        :current-page.sync="currentPage"
        @query-change="queryChange"
        @row-click="handleRowClick"
        class="data-table"
      >
        <el-table-column fixed prop="_id" width="200" label="Id"></el-table-column>
        <el-table-column
          width="100"
          fixed
          :formatter="statusFormatter"
          prop="verification.declinedBy"
          label="Status"
        ></el-table-column>
        <el-table-column
          fixed
          prop="profile.firstName"
          width="200"
          label="First Name"
        ></el-table-column>
        <el-table-column
          prop="profile.lastName"
          width="200"
          label="Last Name"
        ></el-table-column>
        <el-table-column
          :formatter="dateFormatter"
          prop="profile.dob"
          width="100"
          label="DOB"
        ></el-table-column>
        <el-table-column width="300" prop="profile.email" label="Email"></el-table-column>
        <el-table-column
          v-if="clearance === roles.associationAdmin"
          :formatter="clubNameFormatter"
          prop="clubName"
          width="300"
          label="Club"
        ></el-table-column>
        <el-table-column
          :formatter="memberTypeFormatter"
          prop="memberTypes"
          width="300"
          label="Member Type"
        ></el-table-column>
        <el-table-column
          :formatter="hasPhotoFormatter"
          prop="hasPhoto"
          width="100"
          label="Photo?"
        ></el-table-column>
        <el-table-column
          :formatter="hasDocumentsFormatter"
          prop="hasDocuments"
          width="100"
          label="Documents?"
        ></el-table-column>
      </data-tables-server>
    </el-row>
  </div>
</template>

<script>
import _ from "lodash";
import FilterPage from "./FilterPage.vue";
import { roles, memberTypes } from "../../utils/constants";

export default {
  name: "PendingVerification",
  components: { FilterPage },
  data() {
    const {
      criteria,
      filters: { status },
      pagination: { pageSize, page, total },
    } = this.$store.state.registrations.pendings;
    return {
      tableProps: {
        border: true,
      },
      filterVisible: false,
      search: criteria,
      statusFilter: status,
      filters: [{ value: criteria }],
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
      currentPage: page,
      pageSize,
      total,
      tableData: [],
      searchResults: [],
      roles,
    };
  },
  mounted() {
    this.fetchStore();
  },
  methods: {
    fetchStore() {
      const { data } = this.$store.state.registrations.pendings;
      if (data.length) {
        this.tableData = data;
        return true;
      }
      return false;
    },
    debouncedInput: _.debounce(function debouncedInput() {
      if (this.search.length >= 3 || this.search.length === 0) {
        this.filters[0].value = this.search;
      }
    }, 500),
    sendFilter(event) {
      // eslint-disable-next-line no-unused-vars
      this.$store.commit("registrations/updatePendingParams", event.status);

      const { status: statusArray } = event;
      const status = statusArray.length > 0 ? statusArray[0] : null;
      this.$store.commit("registrations/updatePendingFilters", status);
      this.statusFilter = status;
      this.sendQuery();
      this.filterVisible = false;
    },
    queryChange(queryInfo) {
      this.pageSize = queryInfo.pageSize;
      this.currentPage = queryInfo.page;
      this.sendQuery();
    },
    sendQuery() {
      this.$store.commit("root/loading", true);
      this.$http
        .get(
          `/nrl/api/v1/admin/members/pending-registrations/data-table/${this.pageSize}/${this.currentPage}`,
          { params: { search: this.filters[0].value, statusFilter: this.statusFilter } }
        )
        .then((response) => {
          this.tableData = _.orderBy(
            response.data.data.pendingRegos,
            ["profile.lastName"],
            ["asc"]
          );
          this.total = response.data.data.total;
          // store
          this.$store.commit("registrations/updatePending", {
            criteria: this.search,
            data: this.tableData,
            pagination: {
              pageSize: this.pageSize,
              page: this.currentPage,
              total: this.total,
            },
          });
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
          this.$customError();
        });
    },
    hasPhotoFormatter(row) {
      return _.get(row, "profile.meta.avatar") ? "Yes" : "No";
    },
    hasDocumentsFormatter(row) {
      return row && row.documents && row.documents.length > 0 ? "Yes" : "No";
    },
    clubNameFormatter(row) {
      return row.registration
        ? _.uniq(_.filter(_.map(row.registration, "club.name"), "length")).join(", ")
        : "N/A";
    },
    memberTypeFormatter(row) {
      return row.registration
        ? row.registration
            .filter((mt) => mt.memberType !== "program")
            .map((mt) => {
              const found = _.find(memberTypes, (m) => m.type === mt.memberType);
              return found && found.name;
            })
            .join(", ")
        : "N/A";
    },
    getFormattedDate(timestamp) {
      return this.moment(timestamp).format("DD-MM-YYYY");
    },
    dateFormatter(row) {
      return row.profile.dob
        ? this.moment(row.profile.dob, "YYYY-MM-DD").format("DD-MM-YYYY")
        : "NA";
    },
    statusFormatter(row) {
      if (row.verification.declinedBy) {
        return "Declined";
      }
      if (row.verification.approvedBy) {
        return "Approved";
      }
      return "Pending";
    },
    handleRowClick(row) {
      const member = this.tableData.find((user) => user._id === row._id);
      _.assign(member, {
        verificationStatus: member.verification.declinedBy ? "Declined" : "Pending",
      });
      this.$router.push({
        name: "pending-registrations.details",
        params: {
          id: member._id,
          member,
        },
      });
    },
  },
  computed: {
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
  },
};
</script>

<style scoped lang="scss">
.text-left {
  text-align: left !important;
}
.data-table {
  width: 100%;
  margin-top: 1rem !important;
}

.el-pagination {
  margin-top: 1rem !important;
}

.search-bar {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.el-select,
.el-input {
  width: 100%;
}

.btn-container {
  text-align: right;
}
</style>
