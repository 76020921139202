<template>
  <div class="admin-users">
    <p>
      Please select the options for the report and submit.
      <br />
      A report will be generated and sent to your account email address.
    </p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row
        :gutter="10"
        v-if="
          clearance === roles.associationAdmin &&
            this.$store.getters['user/activeRole'].national_id === 32
        "
      >
        <el-col :span="12">
          <el-form-item prop="club" label="Club">
            <el-select clearable v-model="filters.club" placeholder="Select a Club">
              <el-option v-for="{ name, _id } in myclubs" :label="name" :key="_id" :value="_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="season" label="Season">
            <el-select v-model="filters.season" filterable multiple placeholder="Select a season">
              <el-option
                v-for="season in seasons"
                :label="season.name"
                :key="season.value"
                :value="season.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="fileType" label="File Type">
            <el-select v-model="filters.fileType" placeholder="Select a file type">
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <action-buttons submit submitText="Submit" :cancel="false" @submit="sendReport" />
  </div>
</template>

<script>
import moment from "moment-timezone";
import ActionButtons from "@/components/ActionButtons.vue";
import { roles, mysidelineSeasons } from "../../../utils/constants";

export default {
  name: "EntityDetailsReport",
  components: {
    ActionButtons
  },
  mounted() {
    if (this.clearance <= roles.associationAdmin) {
      this.$store.commit("root/loading", true);
      this.$http
        .get("/nrl/api/v1/admin/clubs")
        .then(res => {
          this.myclubs = res.data.data;
          this.$store.commit("root/loading", false);
        })
        .catch(() => {
          this.$store.commit("root/loading", false);
        });
    }
  },
  data() {
    return {
      filters: {
        fileType: "csv",
        season: ""
      },
      filterRules: {},
      fileTypes: [{ name: "CSV", type: "csv", default: true }],
      roles,
      myclubs: []
    };
  },
  methods: {
    sendReport() {
      this.$refs.filters.validate(valid => {
        if (valid) {
          const activeRole = this.$store.getters["user/activeRole"];
          this.$store.commit("root/loading", true);
          const body = {
            reportType: "dispensation",
            fileType: this.filters.fileType,
            filters: {
              club: this.filters.club,
              seasons: this.filters.season,
            },
            meta: {
              timezone: moment.tz.guess(),
              activeRole
            }
          };
          const url = "/nrl/api/v1/admin/reports";
          this.$http
            .post(url, body)
            .then(response => {
              this.$store.commit("root/loading", false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit("root/loading", false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    }
  },
  computed: {
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    seasons() {
      return mysidelineSeasons.map(v => ({ name: v.toString(), value: v }));
    }
  }
};
</script>
