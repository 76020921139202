<template>
  <div class="admin-users">
    <p>
      Please select the options for the report and submit.
      <br>
      A report will be generated and sent to your account email address.
    </p>
    <el-form
      label-position="top"
      :model="filters"
      :rules="filterRules"
      ref="filters"
      class="login-form"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="fileType" label="File Type">
            <el-select
              v-model="filters.fileType"
              placeholder="Select a file type"
            >
              <el-option
                v-for="fileType in fileTypes"
                :label="fileType.name"
                :key="fileType.type"
                :value="fileType.type"
                :default="fileType.default"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="clearance < roles.clubAdmin">
        <el-col :span="12">
          <el-form-item prop="selectedCompetition" label="Competition">
            <el-select
              clearable
              filterable
              v-model="filters.selectedCompetition"
              placeholder="Select a competition"
            >
              <el-option
                v-for="entity in competitions"
                :label="entity.name"
                :key="entity._id"
                :value="entity._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item prop="Team" label="Team">
            <el-select
              clearable
              v-model="filters.selectedTeam"
              placeholder="Select a team"
              multiple
            >
              <el-option
                v-for="{ name, _id } in teams"
                :label="name"
                :key="_id"
                :value="_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item prop="ageLevel" label="Age Level">
            <el-select
              clearble
              v-model="filters.selectedAgeLevel"
              placeholder="Select a team age level"
              multiple
            >
              <el-option
                v-for="{ name } in ageLevels"
                :label="name"
                :key="name"
                :value="name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter='10' v-if='!filters.selectedRequiresNonPlayerRole || filters.selectedRequiresNonPlayerRole.length < 1'>
        <el-col :span="8">
          <el-form-item prop="nonPlayerSquadRole" label="Squad Role">
            <el-select 
              clearable 
              v-model="filters.selectedNonPlayerRole" 
              placeholder="Select a Squad Role"
              multiple 
            >
              <el-option
                v-for="(item, index) in squadRoles"
                :key="index.type"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter='10' v-if='!filters.selectedNonPlayerRole || filters.selectedNonPlayerRole.length === 0'>
        <el-col :span="8">
          <el-form-item prop="requiresNonPlayerSquadRole" label="Team Requires Squad Role">
            <el-select 
              clearable 
              v-model="filters.selectedRequiresNonPlayerRole" 
              placeholder="Select a Squad Role"
               
            >
              <el-option
                v-for="(item, index) in squadRoles"
                :key="index.type"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <action-buttons
      submit
      submitText="Submit"
      :cancel="false"
      @submit="sendReport"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment-timezone';
import ActionButtons from '@/components/ActionButtons.vue';
import {
  errormsg, allSeasons, roles, ageLevels, nonPlayerRoles
} from '@/utils/constants';

export default {
  name: 'TeamSquadAccreditationReport',
  components: {
    ActionButtons
  },
  mounted() {
    if (this.clearance < this.roles.clubAdmin) {
      this.$http.get('/nrl/api/v1/admin/competitions')
        .then((res) => {
          this.competitions = res.data.data;
        })
        .catch(() => {
          this.$customError('Unable to fetch competitions');
        });
    }

    this.$store.commit('root/loading', true);
    this.$http.get('/nrl/api/v1/admin/teams')
      .then((res) => {
        this.myteams = res.data.data;
        this.$store.commit('root/loading', false);
      })
      .catch(() => {
        this.$store.commit('root/loading', false);
      });
  },
  data() {
    return {
      competitions: [],
      filters: {
        fileType: 'csv',
        selectedTeam: null,
        selectedAgeLevel: null,
        selectedCompetition: null,
        selectedNonPlayerRole: null, 
        selectedRequiresNonPlayerRole: null,
      },
      filterRules: {
        selectedTeam: {
          required: true,
          trigger: 'blur',
          message: errormsg.select_option,
        },
      },
      fileTypes: [
        { name: 'CSV', type: 'csv', default: true }
      ],
      myteams: [],
      allSeasons,
      ageLevels,
      roles,
      squadRoles: nonPlayerRoles,
      squadRole: null,
    };
  },
  methods: {
    sendReport() {
      this.$refs.filters.validate((valid) => {
        if (valid) {
          this.$store.commit('root/loading', true);
          const body = {
            reportType: 'team-squad-accreditation-report',
            fileType: this.filters.fileType,
            filters: {
              teamFilter: this.filters.selectedTeam,
              ageLevel: this.filters.selectedAgeLevel,
              competitionId: this.filters.selectedCompetition,
              nonPlayerSquadRole: this.filters.selectedNonPlayerRole,
              requiresNonPlayerSquadRole: this.filters.selectedRequiresNonPlayerRole,
            },
            meta: {
              timezone: moment.tz.guess(),
            }
          };
          const url = '/nrl/api/v1/admin/reports';
          this.$http
            .post(url, body)
            .then((response) => {
              this.$store.commit('root/loading', false);
              this.$customSuccess(response.data.message);
            })
            .catch(() => {
              this.$store.commit('root/loading', false);
              this.$customError();
            });
        } else {
          return false;
        }
        return true;
      });
    }
  },
  computed: {
    seasons() {
      return allSeasons.map(v => ({ name: v.toString(), value: v }));
    },
    clearance() {
      return this.$store.getters['user/activeRole'].type;
    },
    teams() {
      if (this.filters.selectedCompetition) {
        const comp = _.find(this.competitions, c => c._id === this.filters.selectedCompetition);
        const teamIds = _.map(comp.teams, t => t._id);
        return _.filter(this.myteams, t => _.includes(teamIds, t._id));
      }
      return this.myteams;
    }
  },
};
</script>
